import React, {useEffect, useState} from 'react';
import Card from "../components/card";
import {FieldGroup, Fieldset, Field, Label} from "../components/fieldset";
import {Button} from "../components/button";
import {Textarea} from "../components/textarea";
import {Dropdown} from "../components/dropdown";
import client from "../lib/axios";
import {useNavigate} from "react-router-dom";
import {Loader} from "../components/loader";
import {Input} from "../components/input";
import {Divider} from "../components/divider";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "../components/dialog";
import {Badge} from "../components/badge";

import Tooltip from '@mui/material/Tooltip';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

// import ButtonMui from '@mui/material/Button';

import {Sidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
// import {Checkmark} from "react-checkmark";

const Search_original = () => {
    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [mp4, setMp4] = useState();
    const [keywordsGenerated, setKeywordsGenerated] = useState();
    const [notifications2, setNotifications2] = useState();
    const [keywords2, setKeywords2] = useState();
    const [dialogTitle, setDialogTitle] = useState();
    const [loading, setLoading] = useState();
    const navigate = useNavigate()
    const [keywords, setKeywords] = useState('');
    const [keywordPlaceholder, setKeywordPlaceholder] = useState('Enter keywords');
    const [vlmModels, setVLModels]  = useState([]);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [userModels, setUserModels] = useState([]);
    const options = ["gpt-4o", "gemini-1.5-flash", "gemini-1.5-pro", "anthropic.claude-3-5-sonnet-20240620-v1:0",
        "anthropic.claude-3-sonnet-20240229-v1:0", "anthropic.claude-3-haiku-20240307-v1:0", "anthropic.claude-3-5-sonnet-20241022-v2:0",
        "openclip-video-embedding", "gpt-4o-openclip-text-embedding", "gemini-1.5-flash-openclip-text-embedding", "gemini-1.5-flash-openclip-video-embedding"];
    const [modelEventIndex, setModelEventIndex] = useState({});
    const [searchId, setSearchId] = useState('');

    const toggleDropdown = () => setIsDropDownOpen(!isDropDownOpen);

    useEffect(() => {

        const fetchVlmModels = async () => {
            try {
                const response = await client.get('/vlmmodels', {
                    headers: {
                        "Authorization": "SeedonkSession " + sessionStorage.getItem("sid"),
                    }
                });
                const userModelsArray = Object.keys(response.data).map(key => response.data[key].toLowerCase());
                setUserModels(userModelsArray);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        
        fetchVlmModels();

        window.addEventListener("mouseup", (e) => {
            if (e.button === 0 && e.target.closest(".dropdown") === null) {
                setIsDropDownOpen(false);
            }
        });
    }, []);


    const navigateSearch = () => {
        navigate("/search")
    }

    const setDialog = (s3_video_path, title, keywordsGenerated, notifications2, keywords2) => {
        setMp4(s3_video_path)
        setDialogTitle(title)
        setIsOpen(true)
        setKeywordsGenerated(keywordsGenerated)

        setKeywords2(keywords2)
        setNotifications2(notifications2)

    }


    const handleSubmit = (e) => {
        if (e != null) {
            e.preventDefault();
        }
        if (loading) {
            return;
        }
        setLoading(true);
        client.post('/assistant', {keywords, vlmModels}, {
            headers: {
                "Authorization": "SeedonkSession " + sessionStorage.getItem("sid"),
            }
        })
            .then((response) => {

                setSearchId(response.data.searchId);

                // group results by date
                const groupedHits = {};

                setModelEventIndex(response.data.model_event_index);

                vlmModels.forEach(model => {
                    const modelKey = Array.isArray(model) ? model[0].toLowerCase() : model.toLowerCase();
                    groupedHits[modelKey] = {};
                });

                vlmModels.lenght = 0;
                let newArray = [];
                if (typeof response.data.hits.hits === 'object' && !Array.isArray(response.data.hits.hits)) {
                    newArray = [
                        ...response.data.hits.hits.relevant_events,
                        ...response.data.hits.hits.other_events
                    ];
                    response.data.hits.hits = newArray;
                }
                response.data.hits.hits.forEach(hit => {
                    if(hit.vlmModels && hit.rawResults)
                    {
                        hit.vlmModels.forEach(model => {

                            if (!vlmModels.includes(model)) {
                                vlmModels.push(model);
                            }

                            const date = hit['rawResults'][model]._source.startDttm.split("T")[0]; // get date part
                            const eventId = hit.eventId; // get event id

                            // ensure that groupedHits contains an entry for every model in this hit
                            if(!groupedHits[model] || typeof groupedHits[model] === "undefined") {
                                groupedHits[model] = {};
                            }

                            // ensure we have an entry for the date
                            if (!groupedHits[model][date] || typeof groupedHits[model][date] === "undefined") {
                                groupedHits[model][date] = {};
                            }
    
                            // ensure we have an entry for the event id
                            if (!groupedHits[model][date][eventId] || typeof groupedHits[model][date][eventId] === "undefined") {
                                groupedHits[model][date][eventId] = [];
                            }
        
                            groupedHits[model][date][eventId].push(hit['rawResults'][model]);
                        });


                    }
                    else
                    {
                        const date = hit._source.startDttm.split("T")[0]; // get date part
                        let qVlmModel = hit._source.vlmModel ? hit._source.vlmModel.toLowerCase() : ''; // get VLM model and convert to lowercase
                        
                        if (!qVlmModel) {
                            qVlmModel = hit._source.vlmModels ? (Array.isArray(hit._source.vlmModels) ? hit._source.vlmModels[0].toLowerCase() : hit._source.vlmModels.toLowerCase()) : '';
                        }
                        const eventId = hit._source.eventId; // get event id
    
                        if (vlmModels.includes(qVlmModel)) {
                            if (!groupedHits[qVlmModel][date]) {
                                groupedHits[qVlmModel][date] = {};
                            }
    
                            if (!groupedHits[qVlmModel][date][eventId]) {
                                groupedHits[qVlmModel][date][eventId] = [];
                            }
                        }
    
                        if (typeof groupedHits[qVlmModel] === "undefined") {
                            groupedHits[qVlmModel] = {}
                        }
    
                        if (typeof groupedHits[qVlmModel][date] === "undefined") {
                            groupedHits[qVlmModel][date] = {}
                        }
    
                        if (typeof groupedHits[qVlmModel][date][eventId] === "undefined") {
                            groupedHits[qVlmModel][date][eventId] = []
                        }
    
                        groupedHits[qVlmModel][date][eventId].push(hit);
                    }
                })

                // Gather all unique dates across all models
                const allDates = new Set();
                vlmModels.forEach(model => {
                    if (groupedHits[model]) {
                        Object.keys(groupedHits[model]).forEach(date => {
                            allDates.add(date);
                        });
                    }
                });

                // Ensure all models have entries for all dates
                vlmModels.forEach(model => {
                    allDates.forEach(date => {
                        if (!groupedHits[model][date]) {
                            groupedHits[model][date] = {}; // Create empty date for the model
                        }
                        
                        // Collect all eventIds for the date across models
                        const allEventIds = new Set();
                        vlmModels.forEach(vlm => {
                            if (groupedHits[vlm][date]) {
                                Object.keys(groupedHits[vlm][date]).forEach(eventId => {
                                    allEventIds.add(eventId);
                                });
                            }
                        });

                        // Ensure each model has all eventIds for the date
                        allEventIds.forEach(eventId => {
                            if (!groupedHits[model][date][eventId]) {
                                groupedHits[model][date][eventId] = [];
                            }
                        });
                    });
                });

                setData(groupedHits);
            })
            .catch((error) => {
                console.log(error);
                if (error.response && error.response.status && error.response.status === 403) {
                    sessionStorage.removeItem("sid");
                    navigate("/login");
                } else {
                    console.error(error);
                    setData([]);
                }
            })
            .finally(() => {
                setKeywordPlaceholder(keywords);
                setKeywords("");
                setLoading(false);
            })

    }


    const handleFeedbackSearch = (eventId, direction, date) => {
        window.alert('Thank you for your fedback on the event!');
        let eventFeedback = {};
        if (direction === 'up') {
            Object.keys(data).forEach(model => {
                if (data[model] && data[model][date]) {
                    if (data[model][date][eventId] && data[model][date][eventId].length > 0) {
                        eventFeedback[model] = "TP";
                    } else {
                        eventFeedback[model] = "FN";
                    }
                }
            });
        } else if (direction === 'down') {
            Object.keys(data).forEach(model => {
                if (data[model] && data[model][date]) {
                    if (data[model][date][eventId] && data[model][date][eventId].length > 0) {
                        eventFeedback[model] = "FP";
                    } else {
                        eventFeedback[model] = "TN";
                    }
                }
            });
        }

        Object.keys(eventFeedback).forEach((model) => {
            const code = eventFeedback[model];
            let searchPayload = {};
            searchPayload['consent'] = "none";
            searchPayload['category'] = `AVA_POC:search:search:${model}`;
            searchPayload['code'] = code;
            searchPayload['comments'] = keywordPlaceholder;

            let payload = {};
            payload['eventFeedback'] = searchPayload
            client.post(`/searchfeedback`, {payload, eventId, searchId}, {
                headers: {
                    "Authorization": "SeedonkSession " + sessionStorage.getItem("sid"),
                }
            })
            .then(response => {
                console.log(`Feedback for event ${eventId} submitted successfully.`);
            })
            .catch(error => {
                console.error(`Error submitting feedback for event ${eventId}:`, error);
                if (error.response && error.response.status === 403) {
                    sessionStorage.removeItem("sid");
                    navigate("/login");
                } else {
                    window.alert(`An error occurred while submitting feedback. ${error}`);
                }
            });
        });
 
    }

    const handleFeedbackNotif = (eventId, direction, modelVersion, captionText) => {
        window.alert('Thank you for your fedback on the notification!');
        /*
        -event_id
        -up/down
        -model_version
        -caption_text
        -search_id
        */

        let payload = {
            "notificationFeedback": {
                "eventId": eventId,
                "value": direction,
                "vlmModel": modelVersion,
                "notification": captionText,
            }
        };
        //
        client.post(`/searchfeedback`, {payload, searchId}, {
            headers: {
                "Authorization": "SeedonkSession " + sessionStorage.getItem("sid"),
            }
        })
        .then(response => {
            console.log(`Caption for model ${modelVersion} for event ${eventId} submitted successfully.`);
        })
        .catch(error => {
            console.error(`Error submitting feedback for model ${modelVersion} for event ${eventId}:`, error);
            if (error.response && error.response.status === 403) {
                sessionStorage.removeItem("sid");
                navigate("/login");
            } else {
                // window.alert("An error occurred while submitting feedback. Please try again.");
                window.alert(`An error occurred while submitting feedback: ${error}`);
            }
        });
    }

    const handleFeedbackOverall = (direction) => {
        window.alert('Thank you for your fedback on the search!');
        let payload = {
            "searchFeedback": {
                "value": direction,
            }
        };

        client.post(`/searchfeedback`, {payload, searchId}, {
            headers: {
                "Authorization": "SeedonkSession " + sessionStorage.getItem("sid"),
            }
        })
        .then(response => {
            console.log(`Overall feedback submitted successfully.`);
        })
        .catch(error => {
            console.error(`Error submitting Overall feedback:`, error);
            if (error.response && error.response.status === 403) {
                sessionStorage.removeItem("sid");
                navigate("/login");
            } else {
                // window.alert("An error occurred while submitting feedback. Please try again.");
                window.alert(`An error occurred while submitting Overall feedback: ${error}`);
            }
        });
        
    }

    const handleOptionClick = (option) => {
        if (vlmModels.includes(option)) {
            setVLModels(vlmModels.filter((item) => item !== option));
        } else {
            setVLModels([...vlmModels, option]);
        }
    };


    const renderResults = () => {
        return (
            <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
            <thead>
            <tr>
                <th className="px-6 py-3 text-left text-L font-medium text-gray-500 uppercase tracking-wider">
                Event
                </th>
                {vlmModels.map(vlmModel => (
                <th
                key={vlmModel}
                className="px-6 py-3 text-left text-L font-medium text-gray-500 uppercase tracking-wider"
                >
                {vlmModel}
                </th>
                ))}
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {(() => {
                const allDates = new Set();
                vlmModels.forEach(model => {
                    if (data[model]) {
                    Object.keys(data[model]).forEach(date => {
                        allDates.add(date);
                    });
                    }
                });

                return Array.from(allDates).sort((a, b) => new Date(b) - new Date(a)).map(date => {
                const eventIdsWithData = new Set();
                vlmModels.forEach(model => {
                    if (data[model] && data[model][date]) {
                        Object.keys(data[model][date]).forEach(eventId => {
                            eventIdsWithData.add(eventId);
                        });
                    }
                });

                return (
                <React.Fragment key={date}>
                <tr>
                    <td
                    colSpan={vlmModels.length + 1}
                    className="px-6 py-4 bg-gray-50 text-left text-s font-medium text-gray-500 uppercase tracking-wider"
                    >
                    {date}
                    </td>
                </tr>
                {Array.from(eventIdsWithData).map(eventId => {
                    // Find the first model that has a thumbnail for this event
                    let thumbnailModel = null;
                    let thumbnailPath = null;
                    let prettyDttm = null;
                    let notifications = {};
                    let keywords = {};
                    for (const model of vlmModels) {
                        if (data[model] && data[model][date] && data[model][date][eventId] && data[model][date][eventId].length > 0) {
                            notifications[model] = data[model][date][eventId][0]._source.notification;
                            keywords[model] = data[model][date][eventId][0]._source.keywords;
                            thumbnailModel = model;
                            thumbnailPath = data[model][date][eventId][0]._source.s3ThumbnailPath;
                            prettyDttm = new Date(data[model][date][eventId][0]._source.startDttm).toLocaleDateString('en-US', {
                                weekday: 'short',
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            });
                        }
                    }

                    return (
                    <tr key={`${date}-${eventId}`}>
                    <td className="px-6 py-4 whitespace-normal">
                    <div className="flex items-center gap-4">
                        <div className="flex flex-col items-center cursor-pointer">
                            <Tooltip 
                                title="This video is relevant for my search!"
                                style={{color:'green'}}
                                onClick={() => handleFeedbackSearch(eventId, 'up', date)}
                            >
                                <ThumbUpIcon/>
                            </Tooltip>
                            <br></br>
                            <Tooltip 
                                title="This video is NOT relevant for my search!"
                                style={{color:'red'}}
                                onClick={() => handleFeedbackSearch(eventId, 'down', date)}
                            >
                                <ThumbDownIcon/>
                            </Tooltip>

                        </div>
                        {thumbnailModel ? (
                            <div className="flex items-center gap-4 cursor-pointer" onClick={() => setDialog(data[thumbnailModel][date][eventId][0]._source.s3VideoPath, data[thumbnailModel][date][eventId][0]._source.notification, null, notifications, keywords)}>
                                <img className="h-16 rounded-xl bg-gray-50" src={thumbnailPath} alt="Thumbnail" />
                                <div>
                                    <Badge color="sky">{prettyDttm}</Badge>
                                </div>
                            </div>
                        ) : (
                            <div className="text-gray-400">No video match for this model</div>
                        )}
                    </div>
                    </td>
                    {vlmModels.map(vlmModel => (
                    <td key={vlmModel} className="px-6 py-4 whitespace-normal">
                        {data[vlmModel] && data[vlmModel][date] && data[vlmModel][date][eventId] && data[vlmModel][date][eventId].length > 0 ? (
                        data[vlmModel][date][eventId].map(hit => {
                        return (
                        <div key={hit._id} className="flex items-center gap-4 hover:cursor-pointer">
                            <div className="flex-1 text-gray-700 cursor-pointer" onClick={() => setDialog(hit._source.s3VideoPath, hit._source.notification, hit._source.keywords, null, null)}>
                                {hit._source.notification  || <>Event Found<br />(Model doesn't generate notifications)</>}
                                <br />
                                <br />
                                <Badge color="sky">{hit._score.toFixed(3)}</Badge>
                            </div>
                            <div className="flex flex-col items-center">
                                <Tooltip 
                                    title="This description is mostly correct" 
                                    style={{color:'green'}}
                                    onClick={(e) => {e.stopPropagation();handleFeedbackNotif(eventId, 'true', vlmModel, hit._source.notification);}}
                                    >
                                        <ThumbUpIcon/>
                                </Tooltip>
                                <br></br>
                                <Tooltip 
                                    title="This description is inaccurate!" 
                                    style={{color:'red'}}
                                    onClick={(e) => {e.stopPropagation();handleFeedbackNotif(eventId, 'false', vlmModel, hit._source.notification, hit._id);}}
                                    >
                                        <ThumbDownIcon/>
                                </Tooltip>
                            </div>
                        </div>
                        );
                        })
                        ) : (
                        <div className="text-gray-400">
                            {modelEventIndex.hasOwnProperty(`${vlmModel}_${eventId}`) ? <span style={{ color: '#C12020' }}>Video Does Not Match Search</span> : <span style={{ color: '#96A4B2' }}>Video Not Processed by Model</span>}
                        </div>
                        )}
                    </td>
                    ))}
                    </tr>
                    );
                })}
                </React.Fragment>
                );
                });
            })()}
            </tbody>
            </table>
            </div>
        );
    };
    
    
    return (
        <div className="flex items-center md:items-start justify-center h-screen md:h-auto w-full">
            <Card className="relative">
                    <Fieldset className="min-w-[500px]">
                        <div className="flex justify-between">
                            <img className="h-12" src="/img/myq_logo.png" alt="myq logo" />
                            <h1 className="mt-3 text-xl mb-4 text-zinc-500 font-sans">Smart Assistant Legacy View</h1>
                        </div>
                        <FieldGroup>
                            <Field>
                                <div className="h-[500px] overflow-y-auto w-full">
                                    {Object.keys(data).length === 0 ? 
                                        (searchId ? <div>No results found</div> : <div>Please enter a search query</div>) 
                                        : renderResults()}
                                </div>
                            </Field>
                            <Field>
                                <Input
                                    placeholder={keywordPlaceholder || 'Enter Keyword'}
                                    value={keywords}
                                    onChange={(e) => setKeywords(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSubmit(e);
                                        }
                                    }}
                                />
                            </Field>
                            <Field>
                                <div className="relative inline-block w-full mb-4 font-medium text-gray-600">
                                    <button
                                        type="button"
                                        onClick={toggleDropdown}
                                        className="w-full px-4 py-2 text-left bg-white border rounded shadow-sm focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-indigo-300"
                                    >
                                        {vlmModels.length > 0 ? vlmModels.join(', ') : 'Choose models'}
                                        <span className="float-right">{isDropDownOpen ? '▽' : '◁'}</span>
                                    </button>
                                    {isDropDownOpen && (
                                        <div className="dropdown absolute z-50 w-full mt-1 bg-white border rounded shadow-lg max-h-60 overflow-y-auto"
                                            style={{ top: '100%', left: 0 }}>
                                            {options
                                                .sort((a, b) => {
                                                    const aSelectable = userModels.includes(a.toLowerCase());
                                                    const bSelectable = userModels.includes(b.toLowerCase());
                                                    if (aSelectable === bSelectable) {
                                                        return 0;
                                                    }
                                                    return aSelectable ? -1 : 1;
                                                })
                                                .map((option, index) => (
                                                    <label key={index} className={`flex items-center p-2 cursor-pointer ${!userModels.includes(option.toLowerCase()) ? 'text-gray-400 cursor-not-allowed' : 'hover:bg-gray-100'}`}>
                                                        <input
                                                            type="checkbox"
                                                            checked={vlmModels.includes(option.toLowerCase())}
                                                            onChange={() => handleOptionClick(option.toLowerCase())}
                                                            className="mr-2"
                                                            disabled={!userModels.includes(option.toLowerCase())}
                                                        />
                                                        {option} {!userModels.includes(option.toLowerCase()) && '- no data generated with this model'}
                                                    </label>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </Field>
                            <form onSubmit={handleSubmit}>
                            <Field>
                                {loading
                                    ? <div className="w-full text-center"><Loader /></div>
                                    : <Button className="w-full" type="submit" color="sky">Search</Button>
                                }
                            </Field>
                            </form>
                        </FieldGroup>
                    </Fieldset>
                
            </Card>

            <Dialog open={isOpen} onClose={setIsOpen}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogBody>
                    <video controls className="w-full">
                        <source src={mp4} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <Divider />
                    <div>
                        {keywordsGenerated?
                            <p>
                            <b className="text-black dark:text-white">Keywords Generated</b>
                            <p className="text-black dark:text-white">{keywordsGenerated}</p>
                            </p>
                        :""}
                        <br/><br/>

                        {notifications2 ?
                            <table border="1">
                                <tr><td>
                                    <b className="text-black dark:text-white">Notifications</b>
                                </td></tr>
                                {Object.keys(notifications2).map((model) => (
                                    <tr><td><b>{model}</b><br/>{notifications2[model]}<br/><br/></td></tr>
                                ))}
                            </table>
                        :""}
                        <br/><br/>
                        {keywords2 ? 
                            <table border="1">
                                <tr><td>
                                    <b className="text-black dark:text-white">Keywords</b>
                                </td></tr>
                                {Object.keys(keywords2).map((model) => (
                                    <tr><td><b>{model}</b><br/>{keywords2[model]}<br/><br/></td></tr>
                                ))}
                            </table>
                        :""}
                    </div>
                </DialogBody>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <div>
                <Sidebar>
                    <Menu>
                        <SubMenu label="Views">
                            <Tooltip title="you are here">
                                <MenuItem> Legacy View </MenuItem>
                            </Tooltip>
                            <MenuItem onClick={navigateSearch}> Simplified View </MenuItem>
                        </SubMenu>
                    </Menu>
                </Sidebar>

                <br/><br/>
                
                {searchId ? (
                <div>
                    <h3>Did you find the event<br/>you were looking for?</h3>
                    <br/>
                    
                    <Button 
                    color="green"
                    onClick={() => {handleFeedbackOverall("true")}}
                    >
                        YES
                    </Button>
                    <br/><br/>
                    <Button 
                    color="red"
                    onClick={() => {handleFeedbackOverall("false")}}
                    >
                        NO
                    </Button>
                </div>
                ) : <div></div>}

            </div>

        </div>
    );
};

export default Search_original;
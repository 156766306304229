import axios from "axios";

const host = window.location.host;
const baseURL = (() => {
  switch (host) {
    case process.env.REACT_APP_API_HOST_PROD:
      return process.env.REACT_APP_API_URL_PROD;
    case process.env.REACT_APP_API_HOST_PREPROD:
      return process.env.REACT_APP_API_URL_PREPROD;
    case process.env.REACT_APP_API_HOST_DEV:
      return process.env.REACT_APP_API_URL_DEV;
    default:
      return process.env.REACT_APP_API_URL_DEV;
  }
})();

const client = axios.create({
  baseURL: baseURL
});

export default client;